export default {
    install(vue) {
        //扫描plugs文件夹下的文件
        const plugs = require.context('@/plugs/', false, /.js$/i).keys();
        const plugDict = {};
        for (let p of plugs) {
            const name = p.replace(/\.\/([\S]+?)\.js/g, '$1');
            let plug = require(`@/plugs/${name}.js`).default;
            if (typeof (plug) === 'function') {
                plug = new plug();
            }
            plugDict[`$${name}`] = plug;
            //安装

            vue.config.globalProperties[`$${name}`] = new Proxy(plug, {
                get(target, key) {
                    if (plug[key] !== undefined) {
                        return plug[key];
                    } else {
                        return vue.config.globalProperties.prototype[key];
                    }
                }
            });
        }
        for (const key in plugDict) {
            const plug = plugDict[key];
            Object.assign(plug, plugDict);
        }
        for (const key in plugDict) {
            const plug = plugDict[key];
            if (plug !== null && plug !== undefined && typeof (plug.init) === 'function') {
                plug.init(vue);
                delete plug['init'];
            }
        }
    }
}