
import jQuery from "jquery";
// import { ElLoading as elLoading } from 'element-plus'


export default function (httpBase) {
    let requestCount = 0;
    let completedCount = 0;
    const http = {
        // eslint-disable-next-line no-unused-vars
        async post(api, form, retType = 'json', options = {
            text: 'loading...',
        }) {

            if (form["guid"] == null) {
                form["guid"] = localStorage.getItem("guid");
            }
            if (form["wsID"] == null) {
                form["wsID"] = localStorage.getItem("wsID");
            }

            if (api.indexOf('http://') == -1 && api.indexOf('https://') == -1) {
                api = `${httpBase}${api}`;
            }
            requestCount++;
            // const loadingInstance = elLoading.service(options);
            let res = await jQuery.post(api, form);
            completedCount++;
            if (requestCount == completedCount) {
                // loadingInstance.close();
            }
            if (retType == 'json' && typeof (res) == 'string') {

                res = JSON.parse(res);

            }
            return res;

        },
        // eslint-disable-next-line no-unused-vars
        async get(api, form, retType = 'json', options = {
            text: 'loading...',
        }) {
            if (api.indexOf('http://') == -1 && api.indexOf('https://') == -1) {
                api = `${httpBase}${api}`;
            }
            if (form["guid"] == null) {
                form["guid"] = localStorage.getItem("guid");
            }
            if (form["wsID"] == null) {
                form["wsID"] = localStorage.getItem("wsID");
            }

            requestCount++;
            // const close = elLoading.service(options);
            let res = await jQuery.get(api, form);
            completedCount++;
            if (requestCount == completedCount) {
                // close();
            }
            if (retType == 'json' && typeof (res) == 'string') {
                res = JSON.parse(res);
            }
            return res;
        }
    };
    return http;
}