let swapi = '';
let shapi = '';
if (location.protocol == 'https:') {
    swapi = `wss://api.dgsc.nobugltd.com`;
    shapi = `https://api.dgsc.nobugltd.com`;
} else {
    swapi = `ws://api.dgsc.nobugltd.com`;
    shapi = `http://api.dgsc.nobugltd.com`;
}

export default {
    // localHttpApiBase: "http://localhost:12008",
    // localHttpApiBase: "wails://wails.localhost:12008",
    localHttpApiBase: "http://127.0.0.1:12008",

    //服务端websocket接口
    localWsApiBase: "ws://localhost:12008",
    serverWsApiBase: swapi,
    // serverWsApiBase: "ws://localhost:5001"

    //服务端http接口
    serverHttpApiBase: shapi,
    // serverHttpApiBase: "http://localhost:5001"
}