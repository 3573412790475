import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App);

/**
 * 路由
 */
import router from '@/router.js';

/**
 * elementPlus
 */
import elementPlus from 'element-plus';
import 'element-plus/dist/index.css';
//图标
import * as elementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(elementPlusIconsVue)) {
    app.component(key, component)
}


/**
 * 右键菜单
 */
import vContextmenu from "v-contextmenu";
//bright dark default
import 'v-contextmenu/dist/themes/default.css'

/**
 * 自定义插件
 */
import customPlugs from './customPlugs';

app.use(elementPlus)
    .use(elementPlusIconsVue)
    .use(customPlugs)
    .use(vContextmenu)
    .use(router)
    .mount('#app');
