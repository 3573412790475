<!-- 官网 -->
<template>
  <div class="w100 h100 col" id="imageIndexPage" style="background: rgb(57, 81, 105)">
    <div id="head" class="row between">
      <div id="headLeft" class="row vCenter hCenter">
        <img id="logo" src="/favicon.ico" alt="" />
        <div id="title">动感视窗 · 壁纸中心</div>
      </div>
      <div id="headRight" class="row vCenter hCenter">
        <a href="https://dgsc.nobugltd.com" id="imgDownload">
          <div>软件下载</div>
        </a>
        <!-- <el-popover title="" :width="660" trigger="click"> -->
        <el-popover title="" :width="220" trigger="click">
          <template #reference>
            <div id="qrcode">联系我们</div>
          </template>
          <div id="qrcodeList" class="row">
            <div class="qrcodeItem col vCenter hCenter">
              <img class="qrcode" src="@/assets/images/wechat2.png" />
              <div class="qrcodeTitle">联系开发者</div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div id="content" class="w100 h100 row full hCenter vCenter">
      <div id="typeList" class="col">
        <div class="typeItem" @click="selectType(type)" :class="{ active: type.ID == selectTypeID }"
          v-for="type in typeList" :key="type.ID">
          <span v-html="type.typeName"></span>
        </div>
      </div>
      <div id="wallpaperListPanel" v-infinite-scroll="next" :infinite-scroll-distance="50"
        :infinite-scroll-disabled="loadData" :infinite-scroll-immediate="true">
        <div id="wallpaperList" class="full">
          <div class="wallpaperItem" v-for="wallpaper in list" :key="wallpaper.ID">
            <div class="operatePanel row vCenter hCenter">
              <div class="operateItem row vCenter" @click="view(wallpaper)">
                <el-icon>
                  <View />
                </el-icon>
                <span class="operateItemText">查看原图</span>
              </div>
              <div class="operateItem row vCenter" @click="download(wallpaper)">
                <el-icon>
                  <Download />
                </el-icon>
                <span class="operateItemText">下载壁纸</span>
              </div>
            </div>
            <img :src="imgServer + wallpaper.cover" alt="" />
          </div>
        </div>
        <div style="height: 30%"></div>
      </div>
    </div>
    <!-- 浏览图片对话框 -->
    <el-dialog v-model="previewImage" :title="wallpaperName" width="1200">
      <img @contextmenu.stop="" :src="previewImageURL" v-if="previewType == 'img'" id="previewImage" alt="" />
      <iframe @contextmenu.stop="" :src="previewImageURL" v-if="previewType == 'url'" id="previewUrl"></iframe>
      <video :src="previewImageURL" controls v-if="previewType == 'video'" id="previewVideo"></video>
    </el-dialog>
    <div class="w100 row vCenter hCenter" id="foot">
      <a href="https://www.nobugltd.com"> Copyright © 2024 吾八戈软件工作室 </a>
      <a href="https://beian.miit.gov.cn/">桂ICP备2023003809号</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "indexPage",
  data() {
    return {
      previewImage: false,
      previewImageURL: "",
      previewType: "img",
      wallpaperName: "",
      typeList: [],
      selectTypeID: -2,
      page: 0,
      pageEnd: false,
      loadData: false,
      list: [],
      imgServer: "//image.nobugltd.com/wallpaper/",
    };
  },
  methods: {
    //下载图片
    download(wallpaper) {
      if (wallpaper.type == 'url') {
        this.$toast.show("该类型无法下载");
        return;
      }
      this.$serws.send("wallpaper.download", { ID: wallpaper.ID });
      let url = `${this.imgServer}${wallpaper.source}`;
      let extName = wallpaper.source.replace(/[\s\S]+?\.([a-z]+)$/g, "$1");
      // 创建一个隐藏的<a>元素
      const link = document.createElement("a");
      link.style.display = "none";

      // 设置href属性为要下载的文件的URL
      link.href = url;

      // 设置download属性以指定下载的文件名
      link.download = wallpaper.wallpaperName + "." + extName;

      // 将链接添加到文档中
      document.body.appendChild(link);

      // 模拟点击事件以触发下载
      link.click();

      // 从文档中删除链接
      document.body.removeChild(link);
    },
    //浏览图片
    view(wallpaper) {
      this.previewType = wallpaper.type;
      this.wallpaperName = wallpaper.wallpaperName;
      this.previewImageURL = this.imgServer + wallpaper.source;
      if (wallpaper.type == 'url') {
        this.previewImageURL = wallpaper.url;
      }

      this.previewImage = true;
    },
    //下一页
    next() {
      if (this.pageEnd == true || this.loadData == true) {
        return;
      }
      this.loadData = true;
      this.page++;
      this.getWallpaperList();
    },
    //选择类型
    selectType(type) {
      if (type.ID == this.selectTypeID) {
        return;
      }
      this.selectTypeID = type.ID;
      this.list = [];
      this.page = 1;
      this.pageEnd = false;
      this.getWallpaperList();
    },
    //获取类型
    async getTypeList() {
      const res = await this.$http.get("/wallpaperType/all.php", {});
      this.typeList = [
        {
          ID: -2,
          typeName: "最新",
        },
        {
          ID: -1,
          typeName: "热门",
        },
      ];
      this.typeList.push(...res.data);
    },
    //获取壁纸
    async getWallpaperList() {
      if (this.pageEnd == true) {
        return;
      }
      this.loadData = true;
      const res = await this.$http.get(
        `/wallpaper/getList.php?typeID=${this.selectTypeID}&page=${this.page}`,
        {}
      );
      if (res.data.length == 0) {
        this.pageEnd = true;
        this.$toast.show("壁纸已加载完");
      }
      this.list.push(...res.data);
      this.$nextTick(() => {
        this.loadData = false;
      });
    },
  },
  async mounted() {
    //获取类型列表
    {
      await this.getTypeList();
    }
  },
};
</script>
<style scoped src="@/assets/css/image.css"></style>
<style>
#imageIndexPage .el-dialog__body {
  padding: 5px;
  overflow: auto;
}
</style>
