export default [
    {
        name: '渐显',
        value: 'defaultEffect',
    },
    {
        name: '高斯模糊',
        value: 'blurEffect',
    },
    {
        name: '左右滚动',
        value: 'leftRightEffect',
    },
    {
        name: '左右3D滚动',
        value: 'leftRight2Effect',
    },
    {
        name: '上下滚动',
        value: 'topBottomEffect',
    },
    {
        name: '上下3D滚动',
        value: 'topBottom2Effect',
    },
    {
        name: '大小变换',
        value: 'scaleEffect',
    },
    {
        name: 'X轴旋转',
        value: 'rotateXEffect',
    },
    {
        name: 'Y轴旋转',
        value: 'rotateYEffect',
    },
];