

let toastPanel = null;
let toastText = null;
let hideTask = null;
export default {
    init() {
        console.log('初始化toast');
        toastPanel = document.createElement('div');
        toastPanel.classList.add("row", "vCenter", "hCenter", "myToast");
        toastPanel.style = ``;

        toastText = document.createElement('div');
        toastText.style = `padding:0.5rem 1.5rem 0.5rem 1.5rem;background:rgba(0, 0, 0, 0.6);border-bottom:1px solid white;`;
        toastText.innerHTML = `测试toast`;
        toastPanel.appendChild(toastText);

        document.body.appendChild(toastPanel);
    },
    show(text, druation = 1000) {
        toastText.innerHTML = text;
        toastPanel.classList.add("showToast");
        clearTimeout(hideTask);
        hideTask = setTimeout(() => {
            toastPanel.classList.remove('showToast');
        }, druation);
    }
}