import { createRouter, createWebHashHistory } from "vue-router";

const vueRouterOptions = {
    history: createWebHashHistory(),
};


let indexPage = require(`@/pages/index.vue`).default;

//壁纸页面作为首页
if (location.hostname.indexOf('image.nobugltd.com') > -1 || location.href.indexOf('/image.html#/') > -1) {
    indexPage = require(`@/pages/image.vue`).default;
    console.log('#####');
}

//自动创建路由信息
Object.defineProperty(vueRouterOptions, "routes", {
    get() {
        const routers = [
            {
                path: "/",
                // redirect: "/index",
                component: indexPage
            },
        ];
        //读取pages文件夹
        const arr = require.context("@/pages/", true, /.vue$/).keys();
        for (const p of arr) {
            let name = p.replace(/^\.\/([\S]+?)\.vue/g, "$1");
            const page = require(`@/pages/${name}`).default;
            routers.push({
                path: `/${name}`,
                component: page,
            });
            // console.log(name, page);
        }
        return routers;
    },
});

const router = createRouter(vueRouterOptions);

export default router;
