export default function (wsBase) {

  const subscribeDict = {};
  let socket = null;
  let connected = false;
  let errorCount = 0;
  //mid:funciton
  let msgHandle = {};
  let connectEvents = [];

  const connect = async () => {

    socket = new WebSocket(wsBase);
    // 连接打开时触发
    socket.addEventListener('open', (event) => {
      errorCount = 0;
      console.log('WebSocket 连接已打开：', event);
      connected = true;
      for (var evt of connectEvents) {
        evt();
      }
    });

    // 接收到服务器消息时触发
    socket.addEventListener('message', (event) => {
      if (event.data == "P") {
        return;
      }
      const wsData = JSON.parse(event.data);
      const mid = wsData.mid;
      if (typeof (msgHandle[`${mid}`]) == 'function') {
        msgHandle[`${mid}`](wsData.data);
        delete msgHandle[`${mid}`];
      }
      if (subscribeDict[wsData.path]) {
        for (const cb of subscribeDict[wsData.path]) {
          cb(wsData);
        }
      }
    });

    // 连接关闭时触发
    socket.addEventListener('close', (event) => {
      console.log('WebSocket 连接已关闭：', event);
      errorCount++;
      connected = false;
      console.log('errorCount', errorCount);
      // if (errorCount > 500) {
      //   return;
      // }
      setTimeout(() => {
        connect();
      }, 2000);
    });

    // 发生错误时触发
    // socket.addEventListener('error', (event) => {
    //   console.error('WebSocket 错误：', event);
    //   connected = false;
    //   setTimeout(() => {
    //     connect();
    //   }, 2000);
    // });
  };

  connect();



  let messageID = 0;
  const ws = {
    //订阅
    subscribe(path, cb) {
      if (subscribeDict[path] == null) {
        subscribeDict[path] = [];
      }
      subscribeDict[path].push(cb);
    },
    //发送数据
    async send(path, data, msgID = null) {
      if (!connected) {
        await new Promise((resolve) => {
          let task = setInterval(() => {
            if (connected) {
              clearInterval(task);
              resolve(true);
            }
          }, 200);
        });
      }
      if (msgID == null) {
        messageID++;
        msgID = messageID;
      }
      const req = {
        path,
        data,
        mid: msgID
      };


      return new Promise((resolve) => {
        msgHandle[`${msgID}`] = resolve;
        socket.send(JSON.stringify(req));
      });

    },
    //发送PING
    async sendPing() {
      if (!connected) {
        return;
      }
      socket.send("P");
    },
    //连接成功后的事件
    connNotify(evt) {
      if (typeof (evt) == 'function') {
        connectEvents.push(evt);
      }
    },
  };
  return ws;
}